import React from 'react';
import Banner from './Banner';
import { Box, Container, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ciiiImage from '../../../Media/ciiiImg.png';
import CIIICentresCard from './CIIICentresCard';

const sectionStyle = {
    py: 6,
    backgroundColor: "#d7e3fc",
    color: "#1C1F35",
};

const sectionStyle2 = {
    py: 6,
    backgroundColor: "#ffffff",
    color: "#1C1F35",
};

const centres = [
    {
        name: "Laxmibai Bhaurao Patil Center for Invention, Innovation & Incubation",
        location: "S. M. Joshi College, Hadapsar, Pune - 411 028",
        website: "http://www.lbp-ciii.org"
    },
    {
        name: "Rayat Centenary Center for Invention, Innovation & Incubation",
        location: "Sector 11, Kharghar - 410 210",
        website: "http://www.rc-ciii.org"
    },
    {
        name: "Marathwada Mitramandal’s Center for Invention, Innovation & Incubation",
        location: "MMCOE Campus, Karvenagar, Pune - 411 052",
        website: ""
    },
    {
        name: "Yashavantrao Chavan Center for Invention, Innovation & Incubation",
        location: "YCIS Campus, Satara - 415 001",
        website: "http://www.yc-iii.org"
    }
];


const CIIICenters = () => {
    return (
        <>
            <Banner title={"CIII Centers"} />

            <Box sx={{ py: 6, color: "#1C1F35" }}>
                <Container>
                    {/* <img
                        src={`${process.env.REACT_APP_STP_URL}/businessSubPages/CIII-web-Intro_result.jpg`}
                        alt="CIII"
                        style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            objectPosition: "center",
                            marginBottom: "2rem"
                        }}
                    /> */}
                    <Typography color="#152265" variant="h4" fontWeight="600" gutterBottom textAlign={'center'}>
                        Scitech CIII (Centre for Invention, Innovation & Incubation)
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            backgroundColor: '#ffffff',
                            py: { xs: 5, md: 5 },
                            px: { xs: 2, md: 12 },
                            width: '100%',
                            maxWidth: '520px',
                            margin: 'auto',
                        }}
                    >
                        <Paper
                            sx={{
                                backgroundColor: '#d7e3fc',
                                padding: '16px',
                                marginBottom: '16px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                        Features of CIII
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body1" sx={{ color: "#5A7184", textAlign: 'justify' }} component="ul">
                                        <li>Established in collaboration with industry partner</li>
                                        <li>Support for Innovation, Startup Incubation, Coworking and Industrial Skill Training on campus</li>
                                        <li>Domain specific high-end equipment and software and related training</li>
                                        <li>Product design development and prototyping facility</li>
                                        <li>Deep Industry-Academia Partnership</li>
                                        <li>Common R&D facility for local MSMEs</li>
                                        <li>4 CIIIs established – 2 at Pune, 1 at Satara, 1 at Navi Mumbai ranging from 5,000 sqft to 20,000 sqft area</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>

                        <Paper
                            sx={{
                                display: 'grid',
                                backgroundColor: '#d7e3fc',
                                padding: '16px',
                                marginBottom: '16px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',

                            }}
                        >
                            <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                        CIII Offerings
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body1" sx={{ color: "#5A7184", textAlign: 'justify' }} component="ul">
                                        <li>
                                            <strong>Incubation</strong>
                                            <ul>
                                                <li>Mentoring</li>
                                                <li>Networking opportunities</li>
                                                <li>Business planning</li>
                                                <li>Access for funding opportunities</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Co-working</strong>
                                            <ul>
                                                <li>Dynamic & secure working infrastructure</li>
                                                <li>Communication facilities</li>
                                                <li>Centralized business support services</li>
                                                <li>Knowledge management support</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Industry Connect</strong>
                                            <ul>
                                                <li>Tailor-made training</li>
                                                <li>Extended R&D facilities for Industries</li>
                                                <li>Opportunities to support Innovators & Startups</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Company Formation</strong>
                                            <ul>
                                                <li>Single window for all legal documentation</li>
                                                <li>Legal formation & Entities IPR Protection</li>
                                                <li>Virtual office address for registration</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Acceleration</strong>
                                            <ul>
                                                <li>Marketing planning</li>
                                                <li>Product testing & certification support</li>
                                                <li>Business valuation & investment advice</li>
                                                <li>Human capital management</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Skill Training</strong>
                                            <ul>
                                                <li>Entrepreneurship Development & Business Training</li>
                                                <li>Product Design and Development</li>
                                                <li>Domain Exploration</li>
                                            </ul>
                                        </li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>

                        <Paper
                            sx={{
                                backgroundColor: '#d7e3fc',
                                padding: '16px',
                                marginBottom: '16px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                        Outcomes
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body1" sx={{ color: "#5A7184", textAlign: 'justify' }} component="ul">
                                        <li>Bringing industry on campus to deliver high-end training and industry-focused career mentoring and coaching</li>
                                        <li>Enhancement of capabilities of innovation ecosystem on academic campuses</li>
                                        <li>Co-location of skill training, entrepreneurship development, innovation and business incubation</li>
                                        <li>Institution-wide transformation through innovation-led thinking</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </Box>
                    <img
                        src={ciiiImage}
                        alt="CIII"
                        style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            objectPosition: "center",
                            marginBottom: "2rem"
                        }}
                    />
                </Container>
            </Box>

            <Box sx={sectionStyle}>
                <Container>
                    <Typography color="#152265" variant="h4" fontWeight="600" align="center" py={4} mb={3}>
                        Center Locations
                    </Typography>
                    <Grid container spacing={3} justifyContent="center">
                        {centres.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <CIIICentresCard item={item} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default CIIICenters;
