import React from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Banner from "../../Components/Banner";

const SingleBlog = () => {
  const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
  const { slug } = useParams();
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .post("https://api.scitechpark.org.in/news/getbyname", { Title: slug })
      .then((response) => {
        setNews(response.data.result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the data:", error);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "75vh", 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!news) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "75vh",
        }}
      >
        <Typography variant="h6">Data not found</Typography>
      </Box>
    );
  }

  return (
    <>
      <Banner
        title={news?.Title}
        fontSize={{ xs: "2rem", md: "4rem", lg: "4rem" }}
      />
      <Box sx={{ bgcolor: "#f4f6fc", padding: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              pb={4}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "nowrap",
                overflowX: "auto",
                gap: 2,
              }}
            >
              {news?.Images?.map((item, index) => (
                <img
                  key={index}
                  src={`${docUrl}${item}`}
                  alt={`${news?.Title}`}
                  style={{ width: "30%", height: "280px" }}
                />
              ))}
            </Box>
            <Typography variant="body1" gutterBottom>
              {news?.Description}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SingleBlog;
