import React from 'react';
import GrowthLabBanner from './growthLabBanner';
import GrowthLabServices from './growthLabServices';
//@ts-ignore
import GrowthLabBenefits from './growthLabBenefits';
import GrowthLabIncubation from './growthLabSelection';

const GrowthLab = () => {
    return (
        <>
            <GrowthLabBanner />
            <GrowthLabServices />
            <GrowthLabIncubation />
            <GrowthLabBenefits />
        </>
    )
}

export default GrowthLab;

