import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    CardMedia,
    Link,
} from '@mui/material';



export default function AwardsCard({ docUrl, item }) {
    return (
        <Card
            sx={{
                maxWidth: 385,
                // mx: 'auto',
                p: 3,
                boxShadow: 0,
                borderRadius: 4,
                backgroundColor: '#fafafa',
            }}
        >
            <CardMedia
                component="img"
                height="190"
                image={`${docUrl}${item?.Images?.[0]}?w=164&h=164&fit=crop&auto=format`}
                alt={item.Title}
                sx={{ borderRadius: "1.3rem" }}
            />
            <CardContent sx={{ p: 0, pt: 3 }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    {new Date(item?.Award_date).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                    })}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {item?.Name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {item.Description}
                </Typography>
                {/* <Link href={item.Link} target="_blank" sx={{ mt: 2, display: 'block', color: '#0275d8' }}>
                    Read More
                </Link> */}
            </CardContent>
        </Card>
    );
}
