import React from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Banner from "../../Components/Banner";

const SingleEvent = () => {
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const { eventslug } = useParams();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);

    console.log("Event slug:", eventslug);

    console.log(event)

    useEffect(() => {
        axios
            .post(
                "https://api.scitechpark.org.in/events/getbyname",
                { Title: eventslug },
                { headers: { "Content-Type": "application/json" } }
            )
            .then((response) => {
                setEvent(response.data.result);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching the event data:", error);
                setLoading(false);
            });

    }, [eventslug]);

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "75vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!event) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "75vh",
                }}
            >
                <Typography variant="h6">Event not found</Typography>
            </Box>
        );
    }

    return (
        <>
            <Banner
                title={event?.Title}
                fontSize={{ xs: "2rem", md: "4rem", lg: "4rem" }}
            />
            <Box sx={{ bgcolor: "#f4f6fc", padding: 8 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            pb={4}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexWrap: "nowrap",
                                overflowX: "auto",
                                gap: 2,
                            }}
                        >
                            {event?.Images?.map((item, index) => (
                                <img
                                    key={index}
                                    src={`${docUrl}${item}`}
                                    alt={`${event?.Title}`}
                                    style={{ width: "30%", height: "280px" }}
                                />
                            ))}
                        </Box>
                        {/* <Typography variant="h6" gutterBottom>
                            Date: {event?.Date}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Location: {event?.Location}
                        </Typography> */}
                        <Typography variant="body1" gutterBottom>
                            {event?.Description}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Happening on {" "}
                            {new Date(event.Start_date).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                            {" "}to{" "}
                            {new Date(event.End_date).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default SingleEvent;
