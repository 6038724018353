import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const AboutUsSection = () => {
    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 4,
                backgroundColor: '#ffffff',
            }}>
                <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    padding: 0,
                }}>
                    <Box sx={{
                        width: '50%',
                        paddingRight: { xs: 3, md: 5 },
                    }}>
                        <Typography
                            variant="body1"
                            fontSize={'1.1rem'}
                            paragraph
                            sx={{
                                color: '',
                                lineHeight: 1.5,
                                textAlign: 'justify',
                                marginBottom: 2,
                            }}>
                            Science and Technology Park, Pune (Scitech Park, Pune) is one of the first Science and Technology Entrepreneurs Park (STEPs) set up in the year 1986, by National Science and Technology Entrepreneurship Development Board (NSTEDB) of Department of Science and Technology (DST), Government of India, within the campus of Savitribai Phule Pune University. Registered as not for profit Trust & Society.
                        </Typography>
                    </Box>
                    <Box sx={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img
                            src={'https://img.freepik.com/free-vector/company-employees-planning-task-brainstorming_74855-6316.jpg?t=st=1733559033~exp=1733562633~hmac=8aa5be6f08bfd46165f40bd33a2ba9583f4923174d3dd80a0043928c3e5a7383&w=996'}
                            alt='About us'
                            style={{
                                width: '60%',
                                maxWidth: '400px',
                                borderRadius: '5%'
                            }} />
                        {/* <Typography variant="h5" fontWeight="600" sx={{
                            color: '#1C1F35',
                            fontSize: '2rem',
                            textAlign: 'center',
                        }}>
                            NIDHI EIR
                        </Typography> */}
                    </Box>

                </Container>
            </Box>
        </>
    )
}

export default AboutUsSection