import React from "react";
import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { staticData } from "./staticData";
import Banner from "./Banner";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SingleGeoinformaticContent = () => {
  const { data } = useParams();
  const navigate = useNavigate();

  // Find the news item with the matching slug
  const item = staticData.find((item) => item.slug === data);
  if (!item) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "75vh",
        }}
      >
        <Typography variant="h6">Data not found</Typography>
      </Box>
    );
  }

  return (
    <>
      <Banner title={item.Title} letterSpacing="5px" />
      <IconButton
        onClick={() => navigate("/geoinformatics")}
        sx={{
          color: "black",
          position: "relative",
          ml: "100px",
          mb: "-50px",
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box
        pt="3rem"
        m="auto"
        sx={{
          maxWidth: "50%",
          minHeight: { xs: "50vh", lg: "50vh" },
        }}
      >
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
          <div className="carousel-indicators">
            {item.Images.map((_, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current={index === 0 ? "true" : undefined}
                aria-label={`Slide ${index + 1}`}
              ></button>
            ))}
          </div>
          <div className="carousel-inner">
            {item.Images.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minHeight: "55vh",
                }}
              >
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
            style={{
              zIndex: 3,
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              width: "10%",
              height: "100%",
              border: "none",
              cursor: "pointer",
            }}
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
              style={{
                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.8)",
              }}
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            style={{
              zIndex: 3,
              position: "absolute",
              top: "0",
              bottom: "0",
              right: "0",
              width: "10%",
              height: "100%",
              border: "none",
              cursor: "pointer",
            }}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
              style={{
                textShadow: "-2px 2px 5px rgba(0, 0, 0, 0.8)",
              }}
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </Box>
      {/* Content Section */}
      <Box sx={{ bgcolor: "#f4f6fc", padding: 8 }}>
        <Typography
          variant="body1"
          fontSize="1.1rem"
          gutterBottom
          my="1rem"
          color="#364356"
          textAlign="justify"
        >
          {item.Description}
        </Typography>
      </Box>

      <Box
        sx={{
          py: 4,
          px: 8,
          backgroundColor: "#ccdbfd",
          color: "#1C1F35",
        }}
      >
        <Grid container spacing={12} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              sx={{ color: "#0099DE", fontWeight: "bold", textAlign: "center" }}
            >
              Clientele
            </Typography>
            <List sx={{ textAlign: "center" }}>
              <ListItem>
                <ListItemText
                  primary={item.Clientele}
                  sx={{ textAlign: "center", color: "#364356" }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SingleGeoinformaticContent;