import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../Media/Logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './navbar.css';

const pages = [
    {
        "label": "Home",
        "href": "/",
        "subpages": [
            {
                "label": "About us",
                "href": "/about-us"
            }
        ]
    },
    {
        "label": "Domains",
        "subpages": [
            {
                "label": "Business Incubation",
                "href": "/business-incubation"
            },
            {
                "label": "Skill Training",
                "href": "/skill-training"
            },
            {
                "label": "Geoinformatics",
                "href": "geoinformatics"
            },
            {
                "label": "CSR",
                "href": "/csr"
            },
            {
                "label": "Sustainability",
                "href": "https://sustainability.scitechpark.org.in/home"
            }
        ]
    },
    {
        "label": "Business Incubation",
        "subpages": [
            {
                "label": "Startup India",
                "href": "/startup-india"
            },
            {
                "label": "Growth Lab",
                "href": "/business-incubation/growth-lab"
            },
            {
                "label": "Our Incubatees",
                "href": "/incubatees"
            },
            {
                "label": "Products Innovation",
                "href": "/products-innovation"
            },
            {
                "label": "NIDHI EIR",
                "href": "/nidhi-eir"
            },
            {
                "label": "NIDHI SSS",
                "href": "/nidhi-sss"
            },
            {
                "label": "NIDHI PRAYAS",
                "href": "/nidhi-prayas",
                "subpages": [
                    {
                        "label": "Equipments",
                        "href": `${process.env.REACT_APP_STP_URL}/equipmentsPdf/DST+Prayas+Shala+-+List+of+Equipments.pdf`,
                        "target": "_blank",
                    }
                ]
            },
            {
                "label": "CIII Centers",
                "href": "/ciii-centers"
            },
            {
                "label": "Awards and Recognition",
                "href": "/awards"
            }
        ]
    },
    {
        "label": "Events",
        "href": "/events"
    },
    {
        "label": "News & Media",
        "href": "/news-and-media"
    },
    {
        "label": "Donation",
        "subpages": [
            {
                "label": "STP Donations",
                "href": "/stp-donations"
            },
            {
                "label": "Green Donations",
                "href": "/green-donations"
            }
        ]
    },
    {
        "label": "Contact Us →",
        "href": "/contact-us"
    }
]

const Navbar = () => {
    const location = useLocation();

    const isActive = (href) => location.pathname === href;
    const handleDropdownClick = (event, href) => {
        if (href) {
            if (href.startsWith('http') || href.startsWith('www')) {
                window.location.href = href;
            } else {
                event.preventDefault();
                window.location.href = href;
            }
        }
    };


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm sticky-top">
            <div className="container">
                <a className="navbar-brand d-flex align-items-center" href="/" style={{ cursor: "pointer" }}>
                    <img src={Logo} alt="Logo" style={{ width: "330px" }} />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className="collapse navbar-collapse"
                    id="navbarNavDropdown"
                >
                    <ul className="navbar-nav ms-auto">
                        {pages.map((page, index) => (
                            <li key={index} className={`nav-item ${page.subpages ? "dropdown" : "" }`}>
                                {page.subpages ? (
                                    <>
                                        <a href={page.href || "#"}
                                            className="nav-link dropdown-toggle"
                                            id={`navbarDropdown-${index}`}
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            onClick={(e) =>
                                                handleDropdownClick(
                                                    e,
                                                    page.href
                                                )
                                            }
                                        >
                                            {page.label}
                                        </a>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby={`navbarDropdown-${index}`}
                                        >
                                            {page.subpages.map((subpage, subIndex) => (
                                                <li key={`${index}-${subIndex}`} className={`${subpage.subpages ? "dropdown-submenu" : ""}`}>
                                                    {subpage.subpages ? (
                                                        <>
                                                            <a
                                                                href={subpage.href || "#"}
                                                                className={`dropdown-item dropdown-toggle ${isActive(subpage.href) ? "active" : ""}`}
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                onClick={(e) => handleDropdownClick(e, subpage.href)}
                                                                target={subpage.href.startsWith('http') ? "_blank" : ""}
                                                                rel={subpage.href.startsWith('http') ? "noopener noreferrer" : ""}
                                                            >
                                                                {subpage.label}
                                                            </a>
                                                            <ul className="dropdown-menu">
                                                                {subpage.subpages.map((nested, nestedIndex) => (
                                                                    <li key={`${index}-${subIndex}-${nestedIndex}`}>
                                                                        <a
                                                                            className={`dropdown-item ${isActive(nested.href) ? "active" : ""}`}
                                                                            href={nested.href}
                                                                            target={nested.href.startsWith('http') ? "_blank" : ""}
                                                                            rel={nested.href.startsWith('http') ? "noopener noreferrer" : ""}
                                                                        >
                                                                            {nested.label}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </>
                                                    ) : (
                                                        <a
                                                            className={`dropdown-item ${isActive(subpage.href) ? "active" : ""}`}
                                                            href={subpage.href}
                                                            onClick={(e) => handleDropdownClick(e, subpage.href)}
                                                            target={subpage.href.startsWith('http') ? "_blank" : ""}
                                                            rel={subpage.href.startsWith('http') ? "noopener noreferrer" : ""}
                                                        >
                                                            {subpage.label}
                                                        </a>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : (
                                    <Link
                                        to={page.href}
                                        className={`nav-link ${isActive(page.href) ? "active" : ""}`}
                                    >
                                        {page.label}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;