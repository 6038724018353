import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';

export default function CustomTabs() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', mt: 4 }}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="inherit"
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
                sx={{
                    '.MuiTab-root': {
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: '#091242',
                        backgroundColor: 'transparent',
                        marginRight: '1rem',
                        '&:hover': {
                            backgroundColor: '#0912421A',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#F27C5C',
                            color: 'white',
                        },
                    },
                    '.MuiTabs-flexContainer': {
                        borderBottom: 'none',
                    },
                }}
            >
                <Tab
                    label="‘Convert Knowledge into Wealth’"
                    sx={{
                        '& .MuiTab-wrapper': { // Targets the label inside the Tab
                            fontSize: '1.5rem', // Increase font size
                            fontWeight: 'bold', // Make it bold
                        },
                    }}
                />


                {/* <Tab label="Our Vision" />
                <Tab label="Our Mission" /> */}
            </Tabs>
            <Box sx={{ mt: 2 }}>
                {tabValue === 0 && (
                    <>
                        <Typography sx={{ color: '#666C89' }}>
                            Scitech Park’s key mandate includes:
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#5A7184", textAlign: 'justify' }}>
                            <ul>
                                <li>Technology acceleration and commercialization</li>
                                <li>Business incubation</li>
                                <li>Science and technology-based advisory services</li>
                                <li>Innovation ecosystem development</li>
                                <li>Policy research</li>
                            </ul>
                        </Typography>
                        <Typography sx={{ color: '#666C89' }}>
                            Being one of the first five Science and Technology Entrepreneurs Parks in India, Scitech Park is proud to be one of the oldest business incubators and a forerunner in India’s entrepreneurship development ecosystem.
                        </Typography>
                    </>
                )}

                {tabValue === 1 && (
                    <Typography sx={{ color: '#666C89' }}>
                        Content for Our Vision tab.
                    </Typography>
                )}
                {tabValue === 2 && (
                    <Typography sx={{ color: '#666C89' }}>
                        Converting knowledge into wealth.
                    </Typography>
                )}
            </Box>
        </Box>
    );
}
