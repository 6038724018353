import React from 'react'
import AboutUsBanner from './AboutUsBanner'
import ClientsSection from '../ContactUsPage/ClientsSection'
import FAQSection from '../ContactUsPage/FAQSection'
import CompanyOverview from './CompanyOverview'
import IncubationServices from './IncubationServices'
import AboutUsSection from './AboutUsSection'

const AboutUs = () => {
    return (
        <>
            <AboutUsBanner />
            <AboutUsSection />
            <CompanyOverview />
            <IncubationServices />
            {/* <ClientsSection /> */}
            {/* <FAQSection /> */}
        </>
    )
}

export default AboutUs;
