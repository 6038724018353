import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import image from '../../../Media/Nidhi_Prayas.jpg';

export default function NidhiEIRCard({ item }) {
    return (
        <Card
            sx={{
                width: 380,
                height: 400,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                    transform: 'scale(1.05)',
                },
                backgroundColor: '#1C2B77',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            {/* Title */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#1C2B77',
                    padding: '2px 12px',
                    borderRadius: '4px',
                    mb: 1,
                    mt: 1,
                    width: '100%',
                }}
            >
                <Box
                    component="img"
                    src={item?.logo || image}
                    alt={item?.logo}
                    sx={{
                        width: 80,
                        height: 80,
                        borderRadius: '50%',
                        mr: 2,
                    }}
                />
                <Typography
                    variant="h5"
                    sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '20px',
                    }}
                >
                    {item?.name}
                </Typography>
            </Box>

            {/* Body */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    padding: '1rem 2rem',
                    flexGrow: 1,
                    // marginBottom: 'px',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: '15px',
                        lineHeight: 1.5,
                        textAlign: 'justify',
                    }}
                    color="textSecondary"
                >
                    {item?.description}
                </Typography>
            </Box>
        </Card>
    );
}
