import React from 'react';
import { Card, Typography, Button, Box } from '@mui/material';

export default function CIIICentresCard({ item }) {
    return (
        <Card
            sx={{
                width: 380,
                height: 250,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                    transform: 'scale(1.05)',
                },
                backgroundColor: '#1C2B77',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            {/* Title Section */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#1C2B77',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    mb: 2,
                    mt: 2,
                    width: '100%',
                }}
            >
                {/* <Box
                    component="img"
                    src={item?.logo || ''}
                    alt={item?.name}
                    sx={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        mr: 2,
                    }}
                /> */}
                <Typography
                    variant="h5"
                    sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '20px',
                    }}
                >
                    {item?.name}
                </Typography>
            </Box>

            {/* Body Section */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    padding: '1rem 2rem',
                    flexGrow: 1,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: '15px',
                        lineHeight: 1.6,
                        textAlign: 'justify',
                        mb: 1,
                        mt: 1,
                    }}
                    color="textSecondary"
                >
                    {item?.location}
                </Typography>
            </Box>

            {/* Footer Section */}
            {/* {item?.website && ( */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#1C2B77',
                        py: '0.5rem',
                        borderRadius: '4px',
                        flexDirection: 'row',
                        mt: 'auto',
                        width: '100%',
                    }}
                >
                    {/* <Box sx={{ flex: 1, textAlign: 'center' }}>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '14px',
                            color: '#fff',
                            fontWeight: 'bold',
                        }}
                    >
                        Location
                    </Typography>
                </Box>

                <Divider
                    orientation="vertical"
                    sx={{
                        height: '40px',
                        borderColor: '#fff',
                        mx: 2,
                    }}
                /> */}


                    <Box sx={{ flex: 1, textAlign: 'center' }}>
                        <Button
                            sx={{
                                fontSize: '14px',
                                textTransform: 'none',
                                color: 'white',
                            }}
                            onClick={() => window.open(item?.website, '_blank')}
                        >
                            Visit Website
                        </Button>
                    </Box>
                </Box>
            {/* )} */}
        </Card>
    );
}
