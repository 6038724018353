import React, { useState } from 'react';
import { Box, Grid, Typography, List, ListItem, Button, Divider, Stack } from '@mui/material';

export default function GrowthLabServices() {
    const [activeSection, setActiveSection] = useState('Formation');

    const content = {
        Formation: [
            "Single window for all legal documentation",
            "Legal formation of entities",
            "IPR Protection",
            "Virtual office address for registration"
        ],
        Incubation: [
            "Mentoring",
            "Networking Opportunities",
            "Business Planning",
            "Access for Funding Opportunities"
        ],
        Acceleration: [
            "Marketing, Planning and Commercialization",
            "Product testing and Certification support",
            "Business valuation and Investment advice",
            "Human Capital Management"
        ],
        CoWorking: [
            "Dynamic and Secure Working Infrastructure",
            "Communication Facilities",
            "Centralized Business Support Services",
            "Knowledge Management Support"
        ]
    };

    return (
        <Box sx={{ backgroundColor: '#d7e3fc', px: 16, py: 4, pb: '3rem' }}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        sx={{ mt: 2, color: "#364356", fontWeight: 'bold', textAlign: 'center' }}
                    >
                        SERVICES OFFERED
                    </Typography>
                    <Divider
                        sx={{
                            width: '10%',
                            mx: 'auto',
                            mt: 1,
                            mb: 3,
                            borderBottomWidth: 2,
                            backgroundColor: '#364356',
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={0}
                    >
                        {['Formation', 'Incubation', 'Acceleration', 'CoWorking'].map((section) => (
                            <Button
                                key={section}
                                onClick={() => setActiveSection(section)}
                                sx={{
                                    backgroundColor: 'white',
                                    color: activeSection === section ? '#2FB8E5' : '#9BA8AD',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    borderRadius: '0px',
                                    border: '1px solid #ddd',
                                    borderRight: 'none',
                                    '&:last-child': {
                                        borderRight: '1px solid #ddd'
                                    },
                                    width: '200px',
                                    '&:hover': {
                                        color: '#2FB8E5',
                                        backgroundColor: 'white',
                                    },
                                }}
                            >
                                {section}
                            </Button>
                        ))}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={8} sx={{ mt: 3 }}>
                    <List sx={{ paddingLeft: '20px', listStyleType: 'disc' }}>
                        {content[activeSection].map((item, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    display: 'list-item',
                                    color: '#364356',
                                    fontSize: '1.2rem',
                                }}
                            >
                                {item}
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
}
