const sectorData = [
    {
        name: "Open Source and Open Platform Technology",
        slug: "open-source-and-open-platform-technology",
    },
    {
        name: "Renewable Energy and Clean Technology",
        slug: "renewable-energy-and-clean-technology",
    },
    {
        name: "Pharma and Biotechnology",
        slug: "pharma-and-biotechnology",
    },
    {
        name: "Agri and Food Processing Technology",
        slug: "agri-and-food-processing-technology",
    },
    {
        name: "Mobile Computing",
        slug: "mobile-computing",
    },
    {
        name: "IT and IT Enabled Services",
        slug: "it-and-it-enabled-services"
    },
    {
        name: "Project Management",
        slug: "project-management"
    },
    {
        name: "Data Centers",
        slug: "data-centers"
    },
    {
        name: "Social Incubation",
        slug: "social-incubation"
    },
    {
        name: "Marine Services",
        slug: "marine-services"
    },
    {
        name: "Cyber Security",
        slug: "cyber-security"
    },
    {
        name: "GeoInformatics",
        slug: "geoinformatics"
    },
    {
        name: "Education",
        slug: "education"
    },
    {
        name: "Electronics and Telecommunication",
        slug: "electronics-and-telecommunication",
    },
];

export default sectorData;
