import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

const GrowthLabCard = ({ company }) => {
    const website = company?.Website;
    const validUrl =
        website && (website.startsWith("http://") || website.startsWith("https://"))
            ? website
            : `http://${website}`;

    return (
        <Box py={1}>

            <Card
                sx={{
                    m: { xs: 1, md: 4 },
                    mx: '8rem',
                    boxShadow: 2,
                }}
            >
                <a
                    href={validUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <CardContent
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        <Typography variant="h4" fontWeight="600" gutterBottom>
                            {company?.Company_name} - {company?.Sector}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            {company?.Website}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {company?.Description}
                        </Typography>
                    </CardContent>
                </a>
            </Card>
        </Box>
    );
};

export default GrowthLabCard;
