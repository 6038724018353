import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import BlogsPage from "./Layouts/Blogs/BlogsPage";
import BannerSection from "./Layouts/HomePage/BannerSection";
import NewsAnnouncements from "./Layouts/HomePage/NewsAnnouncements/NewsAnnouncements";
import BusinessIncubation from "./Layouts/HomePage/BusinessIncubation/BusinessIncubation";
import OurMemberships from "./Layouts/HomePage/OurMemberships/OurMemberships";
// import Initiatives from "./Layouts/HomePage/Initiatives/Initiatives";
import Events from "./Layouts/HomePage/Events/Events";
import AwardPage from "./Layouts/AwardsPage/AwardPage";
import ContactUsPage from "./Layouts/ContactUsPage/ContactUsPage";
import EventPage from "./Layouts/EventPage.js/EventPage";
import GeoinformaticsPage from "./Layouts/GeoinformaticsPage/GeoinformaticsPage";
import AboutUs from "./Layouts/AboutUs/AboutUs";
import BusinessIncubationBySectorPage from "./Layouts/BusinessIncubationBySectorPage/BusinessIncubationBySectorPage";
import MembershipPage from "./Layouts/MembershipPage/MembershipPage";
import SingleBlog from "./Layouts/Blogs/SingleBlog";
import Header from "./Components/Header";
import Domains from "./Layouts/HomePage/Domains/Domains";
import SpecialProjects from "./Layouts/HomePage/SpecialProjects/SpecialProjects";

import ScrollTop from "./Components/ScrollTop";
import DstNidhi from "./Layouts/DST-NIDHI/DstNidhi";
import StartupIndia from "./Layouts/BusinessIncubation/StartupIndia/StartupIndia";
import ProductsInnovation from "./Layouts/ProductsInnovation/ProductsInnovation";
import BusinessIncubationPage from "./Layouts/BusinessIncubationPage/BusinessIncubationPage";
import SkillTraining from "./Layouts/SkillTraining/SkillTraining";
import CSR from "./Layouts/CSR/CSR";
import CIIICenters from "./Layouts/BusinessIncubation/CIIICenters/CIIICenters";
import STPDonations from "./Layouts/STPDonations/STPDonations";
import GreenDonations from "./Layouts/GreenDonations/GreenDonations";
import AnnouncementPage from "./Layouts/AnnouncementPage/AnnoncementaPage";
import SingleEvent from "./Layouts/EventPage.js/SingleEvent";

import NidhiSSP from "./Layouts/BusinessIncubation/NidhiSSP/nidhiSSP";
import GrowthLabBi from "./Layouts/BusinessIncubation/growthLab/growthLab";
import BusinessIncubatees from "./Layouts/BusinessIncubation/incubatees/incubatees";
import NidhiPrayasProgram from "./Layouts/BusinessIncubation/NidhiPrayas/NidhiPrayas";
import GrowthLab from "./Layouts/BusinessIncubation/growthLab/growthLab";
import NidhiEIR from "./Layouts/BusinessIncubation/NidhiEIR/NidhiEIR";
import SingleGeoinformaticContent from "./Layouts/GeoinformaticsPage/SingleGeoinformaticContent";
import UpcomingEvents from "./Layouts/EventPage.js/UpcomingEvents";

function App() {
  return (
    <Router>
      <div className="App">
        <div id="back-to-top-anchor" style={{ position: 'absolute', top: 0 }} />
        <Header />
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <BannerSection />
                <NewsAnnouncements />
                <Domains />
                <BusinessIncubation />
                <SpecialProjects />
                <Events />
                <OurMemberships />
              </>
            }
          />

          <Route path="/about-us" element={<AboutUs />} />

          <Route path="/business-incubation" element={<BusinessIncubationPage />} />
          <Route path="/skill-training" element={<SkillTraining />} />
          <Route path="/geoinformatics" element={<GeoinformaticsPage />} />
          <Route path="/geoinformatics/:data" element={<SingleGeoinformaticContent />} />
          <Route path="/csr" element={<CSR />} />

          <Route path="/dst-nidhi" element={<DstNidhi />} />
          <Route path="/startup-india" element={<StartupIndia />} />
          <Route path="/growth-lab" element={<GrowthLab />} />
          <Route path="/products-innovation" element={<ProductsInnovation />} />
          <Route path="/ciii-centers" element={<CIIICenters />} />
          <Route path="/awards" element={<AwardPage />} />

          <Route path="/events" element={<EventPage />} />
          <Route path="/event/:eventslug" element={<SingleEvent />} />
          <Route path="/upcomingEvents" element={<UpcomingEvents />} />

          <Route path="/news-and-media" element={<BlogsPage />} />
          <Route path="/announcements" element={<AnnouncementPage />} />

          <Route path="/stp-donations" element={<STPDonations />} />
          <Route path="/green-donations" element={<GreenDonations />} />

          <Route path="/contact-us" element={<ContactUsPage />} />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/business-incubation/:sector" element={<BusinessIncubationBySectorPage />} />
          <Route path="/nidhi-sss" element={<NidhiSSP />} />
          <Route path="/nidhi-prayas" element={<NidhiPrayasProgram />} />
          <Route path="/nidhi-eir" element={<NidhiEIR />} />
          <Route path="/business-incubation/growth-lab" element={<GrowthLabBi />} />
          <Route path="/incubatees" element={<BusinessIncubatees />} />
          <Route path="/blogs/:slug" element={<SingleBlog />} />
          {/* <Route path="/associations/:slug" element={<AssociationDetail />} />
          <Route path="/news-and-media/" element={<NewsAndMediaPage />} />
          {/* <Route path="/business-incubator/:slug" element={<GrowthLabDetail />} /> */}


          <Route path="/memberships" element={<MembershipPage />} />
          <Route path="/growth-lab/:sector" element={<BusinessIncubationBySectorPage />} />
          <Route path="/news-and-media/:slug" element={<SingleBlog />} />
        </Routes>
        <Footer />
        <ScrollTop />
      </div>
    </Router>
  );
}

export default App;
