import React from "react";
import { Box, Typography } from "@mui/material";
import Image from "../../../Media/growthlab.png";
import "../../../fonts/fonts.css"; // Ensure this contains your @font-face rule for custom fonts

const growthLabBanner = ({
    fontFamily = "sans-serif", // Default font family
    minHeight = { xs: "40vh", md: "30vh", lg: "20vh" },
    fontSize = { xs: "2rem", md: "4rem", lg: "6rem" },
    letterSpacing = "0px",
}) => {
    console.log("Environment Variables:", process.env);
    console.log("STP URL:", process.env.REACT_APP_STP_URL);

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: minHeight,
                backgroundColor: "#243078",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 4,
                color: "white",
            }}
        >
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    textAlign: "center",
                }}
            >
                {/* Circular background wrapper */}
                <Box
                    sx={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: "#fcfbdc",
                            width: "200px", // Circle width
                            height: "200px", // Circle height
                            borderRadius: "50%", // Makes it circular
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional shadow
                            // padding:"1rem"
                        }}
                    >
                        <img
                            src={Image}
                            alt="Growth Lab"
                            style={{
                                width: "80%", // Image width inside the circle
                                height: "80%", // Image height inside the circle
                                objectFit: "contain", // Keeps image proportions
                                // borderRadius: "50%", // Optional, makes the image circular
                            }}
                        />
                    </Box>
                </Box>

                {/* Title */}
                <Typography
                    variant="h1"
                    fontSize={fontSize}
                    fontWeight="600"
                    sx={{
                        fontFamily: fontFamily,
                        letterSpacing: letterSpacing,
                    }}
                >
                    Growth Lab
                </Typography>
            </Box>
        </Box>
    );
};

export default growthLabBanner;
