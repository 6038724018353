import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const AwardBanner = () => {
    return (
        <Box
            sx={{
                width: "100%",
                minHeight: { xs: "60vh", md: '35vh', lg: '70vh' },
                backgroundImage: `url(https://tech.allianz.com/en/who-we-are/awards-and-recognition/_jcr_content/root/parsys/stage_copy_copy/stageimage.img.82.3360.jpeg/1644929585383/award-top-banner.jpeg)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 4,
                color: "white",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    textAlign: "center",
                }}
            >
                <Typography
                    variant="h1"
                    fontSize={{ xs: "2rem", md: "3.5rem", lg: "7rem" }}
                    fontWeight="600"
                    sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)" }}
                >
                    AWARDS
                </Typography>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
                    <Grid item xs={10} md={2.5}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Top Honors
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                40+
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={2.8}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Awards Received
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                100+
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={2.5}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Years of excellence
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                30+
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default AwardBanner;
