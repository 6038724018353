import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import IncubateeBanner from './incubateeBanner';

const incubateesData = [
    'M/S. Ravish Systems',
    'M/S. Probity soft Pvt. Ltd.',
    'M/S. GeneOmbio Tech. Pvt. Ltd.',
    'M/S. Gisdox Pvt Ltd.',
    'M/S SDP Technopark',
    'M/S. Ayugen Biosciences Pvt. Ltd.',
    'M/S Ross Lifesciences Pvt. Ltd.',
    'M/S. Deep Blue Tech. Pvt. Ltd.',
    'M/S. Omni-Bridge System Pvt. Ltd.',
    'M/S. Toshvi Computer Services',
    'M/S. Ganak Systems Pvt. Ltd.',
    'M/S. Data 64 Techno Solutions Pvt. Ltd.',
    'M/S. Qui Vive Consultancy Services.',
    'M/S. Bioleum Resources Pvt. Ltd.',
    'M/S. Fusionone Info Pvt. Ltd.',
    'M/S. Dnyavardhini',
    'M/S. Arka Technologies',
    'M/S. Pesto Controls',
    'M/S. Heliac Energy Pvt. Ltd.',
    'M/S. Sofomo Embedded',
    'M/S. Chanakya Software',
    'M/S. Shukla BioTech',
    'M/S Kim Engineering',
    'M/S. Mihir Enterprises',
    'M/S. Real IT Services Pvt. Ltd.',
    'M/S. IMCT',
    'M/S. Soft Pro',
    'M/S. Future Soft Informatics Pvt. Ltd.',
    'M/S. Science & Technology Ventures Ltd.',
    'WatchWitz Technologies Pvt. Ltd.',
    'M/S. AART',
    'IZON Technology Private Limited',
    'M/S. Relationware',
    'M/S. GraphiCAD Systems Pvt. Ltd.',
    'M/S. Sangram Career Academy',
    'M/S. VB Infotech Pvt. Ltd.',
    'M/S. KV Datamatics Pvt. Ltd.',
    'M/S. VIVVID Renewable Energies Ltd.',
    'M/S. BVG Waste Management Pvt Ltd',
    'M/S. Satyaniketan Data Management Center',
    'M/S Omura Designs',
    'M/S Inventa Infrastructure Pvt.Ltd.',
    'M/S AiTOS Solutions Pvt. Ltd.',
    'M/S Cloud Informatix',
    'M/S Aavanira Biotech Pvt. Ltd..',
    'M/S System Application and Compliance Point Pvt. Ltd. (SACP)',
    'M/S Cluster One Creative solutions Pvt. Ltd.',
    'M/S Tirubaa Technologies Pvt. Ltd.',
    'M/S Octopace Engineering Solutions Pvt. Ltd.',
    'M/S Hanul Technologies Pvt. Ltd',
    'M/S Cubane Speciality Chemicals Pvt. Ltd.',
    'M/S Signmod Pharmaceuticals Pvt. Ltd.',
    'M/S Syncroute Infranet Technology Pvt. Ltd.',
    'M/S nCorpuscle Technologies Pvt. Ltd.',
    'M/S Siddhivinayak Agri Processing Pvt. Ltd.',
    'M/S Kshiti Clean Tech Private Limited.',
    'M/S Geo EPC Management Advisors Pvt. Ltd.',
    'M/S ClubHack',
    'M/S Fluxonix Security Solutions Pvt. Ltd.',
    'M/S Aqua Infra Strategies Pvt. Ltd.',
    'M/S Mastermind Infosolutions and Consultancy LLP',
    'M/S Gangotree Eco Technologies Pvt. Ltd.',
    'M/S K-Ziero Tech Services Pvt. Ltd.',
    'M/S Cloud IT Solutions Pvt. Ltd.',
    'M/S Revolta Motors Pvt. Ltd',
    'M/S Lexcode Regulatory Compliance Technologies Pvt. Ltd.',
    'M/S Sunrise Skill Development Pvt. Ltd.',
    'M/S Vishwjeet Green Power Technology Pvt. Ltd.',
    'M/S Membrane Applications Pvt. Ltd.',
    'M/S Jampot Photonics Pvt. Ltd.',
    'M/S Junco Technologies LLP',
    'M/S GSB Learning Corp',
    'M/S Turnhere Design House Pvt. Ltd.',
    'M/S DHI Networks Pvt. Ltd.',
    'M/S GetMy Solutions Pvt. Ltd.',
    'M/S Tork Motors Pvt. Ltd.',
    'M/S Amclin Lifesciences Pvt. Ltd.',
    'M/S Oxolotl Biotechnologies Pvt. Ltd.',
    'M/S Pixy Electric Cars Pvt. Ltd.',
    'Adyagaia Private Limited',
    'Mahtava Solutions LLP',
    'M/S Lycan Electric Pvt. Ltd.',
    'M/S Businet Solutions Pvt.Ltd.',
    'M/S Numbaa Virtual Pvt. Ltd.',
    'M/S Udaradhi Technologies Pvt. Ltd.',
    'M/S Archat Technologies Pvt. Ltd.',
    'Samsara Paedicare',
    'Squateaase',
    'Dr. D.B. jadhav',
    'M/S. Aditya Dnyan Solutions Pvt. Ltd.',
    'Sparken IT Solutions Pvt. Ltd.',
    'Axcss Ventures LLP',
    'Matel Motion and Energy Solutions Pvt. Ltd.',
    'Tenon Meditech LLP',
    'Palash IVF Solutions Pvt. Ltd.',
    'Industill Farmtech Pvt. Ltd.',
    'G. D. Environmental Pvt. Ltd.',
    'Arcatron Mobility Pvt. Ltd.',
    'SRKW Bio Innovation Pvt Ltd',
    'Mr. Amol Gunale',
    'Mr. Manjul Manivesh',
    'Mr. Mohammed Zameer',
    'Mr.Sandanamudi Anuddep',
    'Ms. Effath Yasmin',
    'Hugon International LLP',
    'Dr. Jyoti Kumbhar',
    'Vishal Salunkhe',
    'Airotor Innovative Solutions Pvt. Ltd.',
    'Combat Robotics India Pvt Ltd',
    'Dr. Rajendrakumar Sharma',
    'Combat Robotics India Pvt Ltd',
    'Shatakshi Wagh',
    'Kumar Kalika',
    'Neenu Kalayil',
    'Greenjoules Private Limited',
    'Asish Mohandas',
    'Diverso Power Systems Pvt. Ltd.',
    'Enviropace Pvt Ltd',
    'India First Robotics Innovation & Research LLP',
    'Ominar Innovations Pvt Ltd',
    'Ashnni Motors Pvt Ltd',
    'JCLEAN WEATHER TECH PVT LTD',
    'InfinityX Tech Systems Pvt Ltd',
    '(Former Name: Ileadership)',
    'Elementik Technologies Pvt Ltd',
    'Monter Technologies Pvt Ltd',
    'Mr. Tejsinh Gaikwad',
    'Magnimous Infotech Pvt Ltd',
    'Rowbotix',
    'Micromation Systems (OPC) Pvt Ltd',
    'Dr. Vijaysinh Sawant',
    'Mr. Madhusudan Oak',
    'Mr. Shriram Mathe',
    'Leotechsa Robotics Pvt Ltd',
    'Mr. Chandrakant Mahajan',
    'Wolt Powertronics',
    'Temparate Technologies Pvt Ltd',
    'RenKube Pvt Ltd',
    'Shuvoneel RAS System Pvt Ltd',
    'Dr. Rajavardhan K',
    'Mr. Yogesh Gupta',
    'Ms. Shalmali Kadu',
    'Deau Technology Pvt Ltd',
    'Stackzeal Pvt Ltd',
    'Mr. Vaibhavkumar Dama',
    'Speedloop Auto Pvt Ltd',
    'Dr. Amita Ajit',
    'Evov Solutions LLP',
    'Zum Helien',
    '3Net TechDriver LLP',
    'Shree Lotus Solution',
    'Illuminismo SkillDx Global LLP',
    'Swapnil Patil',
    'Rajhans Chavan',
    'Lastmile Digital Solutions Pvt Ltd',
    'Medyseva Technologies Pvt Ltd',
    'Teplu Learning Pvt Ltd',
    'Triz AI Pvt Ltd',
    'Projoy Pvt Ltd',
    'Saphite Technologies Pvt Ltd',
    'Xefficient Pvt Ltd',
    'Vaishvik Innovative Solutions LLP',
    'Tecrient Space Pvt Ltd',
    'Farmon Agristack Pvt Ltd',
    'Eglence Mobility Pvt Ltd',
    'Biosustain Labs Pvt Ltd',
    'Sagar Gawande',
    'Areete Business Solutions Pvt Ltd',
    'Btech Smart Solutions Pvt Ltd',
    'Jayakrishnan Namboothiri P N',
    'Harisi Pvt Ltd',
    'Gandhali Tophkhane',
    'Autofina Robotics Pvt Ltd',
    'Electromotion E-Vidyut Vehicles Pvt Ltd',
    'Maxcalling Software Technologies Pvt Ltd',
    'AQ Automations Pvt Ltd',
    'Vrudhi Educational And Technological Services Pvt. Ltd.',
    'Intelligence Craft Engine Pvt Ltd',
    'Electromotion E-Vidyut Vehicles Pvt Ltd',
    'Raycura Medical Technologies Pvt Ltd',
    'Resonating Mindz Pvt Ltd',
    'C-Probe Technologies LLP',
    'Inovec Solutions Pvt Ltd',
    'AICAN Pvt Ltd',
    'Genisights Pvt Ltd',
    'PupilMesh Pvt Ltd',
    'WaveNxD Technologies Pvt Ltd',
    'IDT Innovation Technologies Pvt Ltd',
    'Jay Bharat Agritech Pvt Ltd',
    'SmartBizz CS Pvt Ltd',
    'Precitorq Transmission Pvt Ltd',
    'Sapient Aerospace Pvt Ltd',
    'Nikol Automotive Pvt Ltd',
    'Vemsa Biotech Pvt Ltd',
    'Lymbedge LLP',
    'Medvolt Tech Pvt Ltd',
    'Qtanea Solutions Pvt Ltd',
    'Hack-X Security Pvt Ltd',
    'Sugamya Digital Solutions Pvt. Ltd.',
    'Strarosa Futuristics Pvt. Ltd.',
    'SYS3E TECHNOLOGIES PVT LTD',
    'Smart Orbiters',
    'Kalaclap Technologies Pvt. Ltd',
    'M/S Natural Solutions & Neutraceuticals Pvt. Ltd.',
    'Agmove Robotics India Pvt Ltd',
    'Mars Healthtech Pvt. Ltd. (After grant)',
    'Daji Industries Pvt. Ltd. (Prayasee)',
    '3Dincise Engineering Pvt. Ltd.',
    'BioMarkIQ Scientific Technologies Pvt. Ltd.',
    'OctoSense Private Limited',
    'Swapratiraksha Aarogya LLP',
    'Mr. Vikram Deshmukh',
    'Wsys Platform Pvt Ltd',
    'Acandamarici Ventures (OPC) Pvt Ltd',
    'AI2AW Systems Pvt. ltd.',
    'Agmove Robotics India Pvt. Ltd.',
    'DB Potential LLP',
    'Pixaflip Technologies',
    'kesava Yatheemedra Infotech Research Pvt Ltd',
    'Nikol Automotive Pvt Ltd',
    'Agrofix Robotics',
    'Mayur Gajare',
    'Dipesh Walte',
    'Pratyusha Cheepu',
    'Mohit Krishna Kanojia',
    'Sharad Kadam',
    'Dr. Deepak Vikhe',
    'IDT Innovation Technologies Pvt. Ltd.',
    'Manish Pungaliya',
    'Genearche Wellness Pvt Ltd',
    'Hasber Machine Pvt Ltd',
    'Rut3 Engineering Private Limited',
    'Alaric Design Pvt. Ltd.',
    'Bosco Ruptake',
    'SHSTRA (Smart Sugarcane Harvesting System Targeting All)',
    'Vemsa Biotech Pvt Ltd',
    'Avisa Automotive',
    'Shripad Ecosys Pvt. Ltd.',
    'Bucolic Kailash Agritech And FMCG Pvt Ltd',
    'Daji Industries Pvt. Ltd.',
    'Sarafraj Ansari',
    'Shshank Shrivastava',
    'Pristine Center for Skill Development and Research Council'
];


const BusinessIncubatees = () => {
    const firstColumn = incubateesData.slice(0, Math.ceil(incubateesData.length / 2));
    const secondColumn = incubateesData.slice(Math.ceil(incubateesData.length / 2));

    return (
        <>
            <IncubateeBanner />
            <TableContainer component={Paper} 
            sx={{
                mt: '35px',
                paddingLeft: '20px',
                overflowX: 'auto'
            }}>
                <Table sx={{ minWidth: 100, maxWidth: 1500, width: '100%' }} aria-label="incubatee table">
                    <TableBody>
                        {firstColumn.map((company, index) => (
                            <TableRow key={index}>
                                <TableCell>{company}</TableCell>
                                <TableCell>{secondColumn[index]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default BusinessIncubatees;
