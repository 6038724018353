import React from 'react';
import { Box, Grid, Typography, Divider, List, ListItem, Stack } from '@mui/material';

export default function GrowthLabIncubation() {
    return (
        <Box sx={{ backgroundColor: '#ffffff', px: 16, py: 4, pb: '3rem' }}>
            <Grid container alignItems="center" justifyContent="center">
                {/* Title Section */}
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        sx={{ mt: 2, color: "#364356", fontWeight: 'bold', textAlign: 'center' }}
                    >
                        GROWTH LAB INCUBATION PROGRAM SELECTION CRITERIA
                    </Typography>
                    <Divider
                        sx={{
                            width: '10%',
                            mx: 'auto',
                            mt: 2,
                            mb: 3,
                            borderBottomWidth: 1,
                            backgroundColor: '#364356',
                        }}
                    />
                </Grid>

                {/* Description Section */}
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '',
                            fontSize: '1.1rem',
                            lineHeight: 1.6,
                            mb: 3,
                        }}
                    >
                        Growth Lab incubates high-tech start-ups through its incubation program. 
                        <br />
                        The selection for this program is quite strict and structured, focusing on the following criteria:
                    </Typography>
                </Grid>

                {/* Criteria Section */}
                <Grid item xs={12}>
                    <List sx={{ paddingLeft: '20px', listStyleType: 'disc' }}>
                        <ListItem sx={{ display: 'list-item', color: '', fontSize: '1.2rem' }}>
                            Innovativeness of technology/idea
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '', fontSize: '1.2rem' }}>
                            Growth Potential
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '', fontSize: '1.2rem' }}>
                            Financial Viability
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '', fontSize: '1.2rem' }}>
                            Business Plan
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '', fontSize: '1.2rem' }}>
                            Social Impact
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '', fontSize: '1.2rem' }}> 
                            Experience, Qualification and Team
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
}
