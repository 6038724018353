import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from "@mui/material";
import BlogCard from "./BlogCard";
import BlogBanner from "./BlogBanner";
import pattern from "../../Media/Pattern.png";
import Valley from "../../Media/valley.png"
// import PdfDropdown from "./PdfDropdown";

const BlogsPage = () => {
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const [news, setNews] = useState([]);
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (v, k) => currentYear - k);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    useEffect(() => {
        fetchAllNews();
    }, []);

    const fetchAllNews = () => {
        axios
            .get("https://api.scitechpark.org.in/news/getall")
            .then((response) => {
                const allNews = response?.data?.result;
                if (Array.isArray(allNews)) {
                    const latestNews = allNews?.reverse();
                    setNews(latestNews);
                } else {
                    console.error("Expected an array but got:", allNews);
                }
            })
            .catch((error) => {
                console.error("Error fetching the news data", error);
            });
    };

    const handleFilter = () => {
        axios
            .post("https://api.scitechpark.org.in/news/newsfilter", {
                Month: month || "",  // Send empty string if no month is selected
                Year: year || "",    // Send empty string if no year is selected
            })
            .then((response) => {
                const { success, result } = response.data;
                if (success) {
                    if (Array.isArray(result)) {
                        const latestNews = result.reverse();
                        setNews(latestNews);
                    } else {
                        console.error("Expected an array but got:", result);
                    }
                } else {
                    setNews([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching the filtered news data", error);
            });
    };

    const handleReset = () => {
        setMonth("");
        setYear("");
        fetchAllNews();
    };

    return (
        <>
            <BlogBanner count={news?.length} />
            <Box
                sx={{
                    py: 12,
                    backgroundColor: "#d7e3fc",
                }}
            >
                <Grid display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <img height="32px" src={pattern} alt="pattern" />
                    <Typography
                        variant="h6"
                        backgroundColor="#eaeff3"
                        fontWeight="500"
                        px="0.5rem"
                    >
                        Our news and media
                    </Typography>
                </Grid>
                <Typography
                    variant="h4"
                    fontWeight="700"
                    color="#1C1F35"
                    textAlign={'center'}
                    p={2}
                >
                    Our Latest News
                </Typography>
                <Grid
                    container
                    justifyContent={"space-around"}
                    // alignItems={"center"}
                    sx={{
                        display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                        gap: { xs: "1rem", sm: "1rem", md: "2rem", lg: "38rem" },
                    }}
                >
                    <Grid item>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mt="2rem"
                        >
                            {/* <PdfDropdown /> */}
                        </Box>
                    </Grid>

                    <Grid item>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mt="2rem"
                        >
                            <FormControl sx={{ minWidth: 100, mr: 1 }} size="small">
                                <InputLabel>Month</InputLabel>
                                <Select
                                    label="Select"
                                    value={month}
                                    onChange={(e) => setMonth(e.target.value)}
                                >
                                    {months.map((monthName, i) => (
                                        <MenuItem key={i} value={(i + 1).toString().padStart(2, "0")}>
                                            {monthName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 100, mr: 1 }} size="small">
                                <InputLabel>Year</InputLabel>
                                <Select
                                    label="Select"
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                >
                                    {years.map((yr) => (
                                        <MenuItem key={yr} value={yr.toString()}>
                                            {yr}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleFilter}
                                size="small"
                                sx={{ mr: 1 }}
                                disabled={!month || !year}
                            >
                                Filter
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleReset}
                                size="small"
                            >
                                Reset
                            </Button>
                        </Box>
                    </Grid>

                </Grid>
                {news.length > 0 ? (
                    <Grid
                        container
                        mt="4rem"
                        justifyContent={"center"}
                        // alignItems={"center"}
                        sx={{
                            display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                            gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                            px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {news.map((item) => (
                            <BlogCard key={item?.News_id} docUrl={docUrl} item={item} />
                        ))}
                    </Grid>
                ) : (
                    <Grid
                        style={{
                            display: "grid",
                            placeItems: "center",
                            py: 50
                        }}
                    >
                        <img
                            src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                            width={"80px"}
                            height={"80px"}
                            alt=""
                        />
                        <Typography >
                            No News available for the selected filters.
                        </Typography>
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default BlogsPage;
