import React from 'react';
import Banner from '../../Components/Banner';
import { Box, Container, Typography } from '@mui/material';

const CSR = () => {
    return (
        <>
            <Banner title={"CSR"} />
            <Box sx={{ py: 6, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" gutterBottom>
                        Lorem ipsum
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquet nunc ut sapien feugiat, ac scelerisque justo sodales. Curabitur auctor, nulla vel tincidunt tempus, sapien nisi varius enim, non pretium lorem mi sit amet lectus. Aenean ullamcorper scelerisque dolor, nec dictum purus suscipit sit amet. Suspendisse potenti. Nam facilisis, ex a suscipit viverra, purus elit consectetur augue, at vestibulum libero libero a lacus.

                        Mauris ac lacus eget enim blandit tincidunt. Integer feugiat tincidunt lectus sit amet tincidunt. Nulla ut ligula eu nulla suscipit condimentum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin quis augue a sem dignissim gravida. Curabitur a felis eu ligula finibus maximus. Phasellus auctor magna id lacus lobortis, et facilisis felis efficitur. Etiam fermentum accumsan fringilla.
                    </Typography>
                </Container>
            </Box>
        </>
    )
}

export default CSR;