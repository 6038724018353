import React from "react";
import { Box, Button } from "@mui/material";

const data = [
    {
        year: "YEAR 01 PRAYASEES",
        link: "https://scitechpark.org.in/wp-content/uploads/2023/05/Round-01-Prayasees_2016-17.pdf",
    },
    {
        year: "YEAR 02 PRAYASEES",
        link: "https://scitechpark.org.in/wp-content/uploads/2023/05/Round-02-Prayasees_2018-19.pdf",
    },
    {
        year: "YEAR 03 PRAYASEES",
        link: "https://scitechpark.org.in/wp-content/uploads/2023/05/Round-03-Prayasees_2019-20.pdf",
    },
    {
        year: "YEAR 04 PRAYASEES",
        link: "https://scitechpark.org.in/wp-content/uploads/2023/05/Round-04-Prayasees_2021-22.pdf",
    },
];

const YearButtons = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                my: "4rem",
                flexWrap: "wrap",
                "@media (max-width: 900px)": {
                    gap: "15px",
                },
                "@media (max-width: 600px)": {
                    flexDirection: "column",
                    gap: "10px",
                },
            }}
        >
            {data.map((item, index) => (
                <Button
                    key={index}
                    href={item.link}
                    target="_blank"
                    sx={{
                        border: "1px solid #0099DE",
                        color: "#0099DE",
                        borderRadius: "20px",
                        padding: "10px 20px",
                        fontSize: "16px",
                        textTransform: "none",
                        transition: "0.3s",
                        "&:hover": {
                            backgroundColor: "#d7e3fc",
                            color: "#0099DE",
                        },
                    }}
                >
                    {item.year}
                </Button>
            ))}
        </Box>
    );
};

export default YearButtons;
