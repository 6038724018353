import React from 'react'
import ContactForm from './ContactForm'
import { Box } from '@mui/material'

const ContactUsPage = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", py: { xs: 1, md: 2, lg: 4 } }}>
            <ContactForm />
        </ Box>
    )
}

export default ContactUsPage;