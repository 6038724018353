import React from 'react'
import Content from './content';
// import Banner from '../../Components/Banner';
import GeoinformaticBanner from './GeoinformaticBanner';

const GeoinformaticsPage = () => {
    return (
        <>
            <GeoinformaticBanner title="Geoinformatics" />
            <Content />
        </>
    )
}

export default GeoinformaticsPage;