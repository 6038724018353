import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    CardMedia,
    Link,
} from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';

export default function BlogCard({ docUrl, item }) {
    const [showFullText, setShowFullText] = useState(false);

    const toggleText = () => setShowFullText(!showFullText);

    const maxLength = 100;
    const isLongDescription = item.Description.length > maxLength;
    const truncatedDescription = isLongDescription && !showFullText
        ? `${item.Description.slice(0, maxLength)}...`
        : item.Description;

    return (
        <Card
            sx={{
                maxWidth: 385,
                p: "1rem 1.2rem",
                boxShadow: 0,
                borderRadius: 4,
                backgroundColor: '#fafafa',
            }}
        >
            <CardMedia
                component="img"
                height="190"
                image={`${docUrl}${item?.Images?.[0]}?w=164&h=164&fit=crop&auto=format`}
                alt={item.Title}
                sx={{ borderRadius: "1rem" }}
            />
            <CardContent sx={{ p: 0, pt: 3 }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    {new Date(item.News_date).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                    })}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {item.Title}
                </Typography>
                <Typography variant="body2" color="textSecondary" textAlign={'justify'}>
                    {truncatedDescription}
                </Typography>
                {item?.Pdf && (
                    <Link
                        href={`https://mystpbucket.s3.ap-south-1.amazonaws.com/${item?.Pdf}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mt: 2, display: 'block', color: '#0275d8' }}
                    >
                        View <PictureAsPdf />
                    </Link>
                )}
            </CardContent>
        </Card>
    );
}
