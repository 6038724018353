import React from "react";
import { Box, Typography, Grid, Link, Chip } from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import LinkIcon from "@mui/icons-material/Link";
import VisibilityIcon from '@mui/icons-material/Visibility';

const initiatives = [
    {
        title: "Business Incubation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        url: "/business-incubation",
        // phone: "9509555893",
        color: "#008bf9",
    },
    {
        title: "Skill Training",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        url: "/skill-training",
        color: "#9773ff",
    },
    {
        title: "Geoinformatics",
        description:
            "Geoinformatics provides GIS solutions, satellite imagery processing, and spatial analysis for planning and innovation.",
        url: "/geoinformatics",
        color: "#55ac68",
    },
    {
        title: "CSR",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        url: "/csr",
        color: "#e88060",
    },

];

export default function Domains() {

    return (
        <Box
            sx={{
                backgroundColor: "#1c2b78",
                color: "white",
                py: 6,
                px: 2,
            }}
        >
            {/* <Typography
                variant="h6"
                textAlign="center"
                mb={1.5}
                sx={{ color: "#008bf9" }}
            >
                Useful Online Services
            </Typography> */}
            <Typography
                variant="h4"
                textAlign="center"
                mb={4}
                sx={{ fontWeight: "bold" }}
            >
                Domains
            </Typography>
            <Grid
                container
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexWrap={'wrap'}
                gap='1rem'
                sx={{
                    // border: '1px solid red'
                }}
            >
                {initiatives.map((initiative, index) => (
                    <Grid item xs={12} md={2.8} lg={2.1} key={index}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                width: "auto",
                                height: "220px",
                                margin: 'auto'
                            }}
                        >
                            <Box
                                mb={3}
                                sx={{
                                    backgroundColor: initiative.color,
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="h6" py="0.5rem">
                                    {initiative.title}
                                </Typography>
                            </Box>
                            <Typography
                                variant="body2"
                                mb={2}
                                p="0rem 1rem"
                                letterSpacing={"0.5px"}
                                sx={{ color: "#666666" }}
                                textAlign={'justify'}
                            >
                                {initiative.description}
                            </Typography>
                            <Link
                                href={initiative.url}
                                textAlign="left"
                                color="inherit"
                                underline="none"
                                p="0rem 1rem"
                                sx={{ display: "flex", alignItems: "center", mb: 2 }}
                            >
                                <VisibilityIcon sx={{ mr: 1, color: "#008bf9" }} />
                                <Typography variant="body2" sx={{ color: "#1c2b78" }}>
                                    view more
                                </Typography>
                            </Link>
                            {/* {initiative.phone && (
                                <Box
                                    display="flex"
                                    // justifyContent="center"
                                    alignItems="center"
                                    p="0rem 1rem"
                                >
                                    <PhoneInTalkIcon
                                        fontSize="small"
                                        sx={{ mr: 1, color: "#008bf9" }}
                                    />
                                    <Typography variant="body2" sx={{ color: "#1c2b78" }}>
                                        Helpline No:
                                    </Typography>
                                    <Chip
                                        sx={{ m: "0rem 1rem" }}
                                        label={initiative.phone}
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                    />
                                </Box>
                            )} */}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
