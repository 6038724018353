import React from 'react';
import { Box, Grid, Typography, Divider, List, ListItem, Stack } from '@mui/material';
import Image from "../../../Media/glbenefits.png";

export default function GrowthLabBenefits() {
    return (
        <Box sx={{ backgroundColor: '#d7e3fc', px: 16, py: 4, pb: '3rem' }}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        sx={{ mt: 2, color: "#364356", fontWeight: 'bold', textAlign: 'center' }}
                    >
                        BENEFITS TO THE INCUBATEE AND CO-WORKING COMPANIES
                    </Typography>
                    <Divider
                        sx={{
                            width: '10%',
                            mx: 'auto',
                            mt: 2,
                            mb: 3,
                            borderBottomWidth: 1,
                            backgroundColor: '#364356',
                        }}
                    />
                </Grid>

                <Grid item xs={12} container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <List sx={{ paddingLeft: '20px', listStyleType: 'disc', textAlign: 'justify' }}>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Common reception, visitors lounge and hangout zones
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Shared use of conference room /discussion rooms with services
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Biometric access
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Individual workstation with power back up and internet connection
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Access to in – house library
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Beverages from the vending machine in pantry
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Free access to business training, information workshops, seminars, conferences, exhibitions, start-up award recommendations/applications
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Mentoring and coaching from our Board of Mentors on a regular basis
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Access to business services from our panel members at a nominal cost
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: '1.2rem' }}>
                                Housekeeping support and parking
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${Image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                minHeight: '400px',
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
