import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import axios from "axios";
import EventCard from "./EventCard";

const UpcomingEvents = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    console.log(events);

    useEffect(() => {
        const fetchUpcomingEvents = async () => {
            try {
                const response = await axios.post(
                    "https://api.scitechpark.org.in/events/getUpcomingEvents"
                );
                setEvents(response?.data?.events);
            } catch (error) {
                console.error("Error fetching upcoming events:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUpcomingEvents();
    }, []);

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    minHeight: { xs: "60vh", md: "35vh", lg: "50vh" },
                    backgroundImage: `url(https://localmedia.org/wp-content/uploads/2021/06/events-1.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 4,
                    color: "white",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "1200px",
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="h1"
                        fontSize={{ xs: "2rem", md: "3.5rem", lg: "6rem" }}
                        fontWeight="600"
                        sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)" }}
                    >
                        Upcoming Events
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    py: 10,
                    backgroundColor: "#f5f5f5",
                }}
            >
                <Typography
                    textAlign="center"
                    variant="h4"
                    fontWeight="700"
                    color="#1C1F35"
                    mb={3}
                >
                    Explore Upcoming Events
                </Typography>
                {loading ? (
                    <Typography textAlign="center" mt={2}>
                        Loading...
                    </Typography>
                ) : (
                    <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        sx={{
                            px: { xs: "3rem", sm: "1rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {events.map((item) => (
                            <Grid item xs={12} sm={6} md={4} key={item?.Event_id}>
                                <EventCard docUrl={docUrl} item={item} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default UpcomingEvents;
